import fomoApi from "./baseApi";

export async function loginWithEmail(email, password) {
    try {
        const res = await fomoApi.post('/auth/login-with-email', {
            email, password
        })
        return res.data.user
    } catch (error) {
        throw new Error(error)
    }
}

export async function logout() {
    try {
        const res = await fomoApi.post('/auth/logout')
        return
    } catch (error) {
        throw new Error(error)
    }
}