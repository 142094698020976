// import CheckTable from "./components/CheckTable";

// import {
//   columnsDataDevelopment,
//   columnsDataCheck,
//   columnsDataColumns,
//   columnsDataComplex,
// } from "./variables/columnsData";
// import tableDataDevelopment from "./variables/tableDataDevelopment.json";
// import tableDataCheck from "./variables/tableDataCheck.json";
// import tableDataColumns from "./variables/tableDataColumns.json";
// import tableDataComplex from "./variables/tableDataComplex.json";
// import DevelopmentTable from "./components/DevelopmentTable";
// import ColumnsTable from "./components/ColumnsTable";
// import ComplexTable from "./components/ComplexTable";

const PartyHub = () => {
  return (
    <div>
    </div>
  );
};

export default PartyHub;
