import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useAuth } from "hooks/useAuth";
import ProtectedRoute from "components/routes/ProtectedRoute";

const App = () => {
  const user = useAuth()

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={
        <ProtectedRoute>
          <AdminLayout />
        </ProtectedRoute>} />
      <Route path="/" element={<Navigate to={user ? "/admin" : "/auth"} replace />} />
    </Routes>
  );
};

export default App;
