import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const ProtectedRoute = ({ children }) => {
    const user = useAuth()
    const location = useLocation();

  // If there's no user, redirect to the login page
  if (!user) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  // If the user is authenticated, render the children components
  return children;
};

export default ProtectedRoute;
