import { createSlice } from "@reduxjs/toolkit";
import { logout as apiLogout, loginWithEmail as apiLoginWithEmail } from "services/authenticationService";

// Initial state
const initialState = {
    value: null,
    loading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        loginSuccess: (state, action) => {
            state.value = action.payload;
            state.loading = false;
        },
        loginFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        logoutSuccess: (state) => {
            state.value = null;
            state.loading = false;
            state.error = null;
        },
    },
});

export const { loginStart, loginSuccess, loginFailure, logoutSuccess } = userSlice.actions;

export const loginWithEmail = (email, password) => async (dispatch) => {
    dispatch(loginStart());
    try {
        const data = await apiLoginWithEmail(email, password);
        dispatch(loginSuccess(data));
    } catch (error) {
        dispatch(loginFailure(error.message));
        alert("Something went wrong.");
    }
};

export const logout = () => async (dispatch) => {
    try {
        await apiLogout();
        dispatch(logoutSuccess());
    } catch (error) {
        alert("Failed to log out.");
    }
};

export const loginWithEmailAsync = (email, password) => async (dispatch) => {
    try {
        dispatch(loginStart());
        const data = await apiLoginWithEmail(email, password);
        dispatch(loginSuccess(data));
    } catch (error) {
        dispatch(loginFailure(error.message));
        alert("Something went wrong.");
    }
};

export default userSlice.reducer;
